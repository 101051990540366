<template>
  <div class="bar_stacked">
    <div class="holder">
      <div>
        <div>
          <h1>marginLeft 支持'auto'</h1>
          <p>自动根据label里最长到那一个决定marginLeft</p>
        </div>
        <span @click="play1">查看</span>
        <span @click="playOut">出场</span>
      </div>
      <div>
        <div>
          <h1>marginTop: 0, marginRight: 0, marginBottom: 0, marginLeft: 0</h1>
          <p>svg图表偏移量</p>
        </div>
        <span @click="play2">查看</span>
      </div>
      <div>
        <div>
          <h1>padding, 默认0.6</h1>
          <p>控制柱子间距</p>
        </div>
        <span @click="play3">查看</span>
      </div>
      <div>
        <div>
          <h1>duration</h1>
          <p>动画持续时长</p>
        </div>
        <span @click="play4">查看</span>
      </div>
      <div>
        <div>
          <h1>delay</h1>
          <p>动画等待时长</p>
        </div>
        <span @click="play5">查看</span>
      </div>
      <div>
        <div>
          <h1>
            ease <a href="https://github.com/d3/d3-ease/tree/v3.0.1" target="_blank">查看easing</a>
          </h1>
          <p>
            加速减速，可选项：easeQuad,easeCubic,easeExp,easeCircle,easeBack。除了easeBack外，其余的加速度强烈程度依次增加
          </p>
        </div>
        <span @click="play6">查看</span>
      </div>
      <div>
        <div>
          <h1>numberVisible</h1>
          <p>是否显示堆叠总数</p>
        </div>
        <span @click="play7">查看</span>
      </div>
      <div>
        <div>
          <h1>numberSuffix</h1>
          <p>堆叠总数后缀</p>
        </div>
        <span @click="play8">查看</span>
      </div>
      <div>
        <div>
          <h1>numberColor</h1>
          <p>堆叠总数文字颜色</p>
        </div>
        <span @click="play9">查看</span>
      </div>
      <div>
        <div>
          <h1>popoverWidth</h1>
          <p>气泡宽度</p>
        </div>
        <span @click="play10">查看</span>
      </div>
      <div>
        <div>
          <h1>popoverHeight</h1>
          <p>气泡高度</p>
        </div>
        <span @click="play11">查看</span>
      </div>
      <div>
        <div>
          <h1>isPopOverVisible</h1>
          <p>是否绘制气泡。 只当堆叠数量为2时看这个参数。当堆叠数量大于2时忽略此参数，因为展示会重叠</p>
        </div>
        <span @click="play12">查看</span>
      </div>
      <div>
        <div>
          <h1>color, 默认['#FF4805', '#FE9805']</h1>
          <p>堆叠柱子颜色</p>
        </div>
        <span @click="play13">查看</span>
      </div>
      <div>
        <div>
          <h1>keys + data</h1>
          <p>项堆叠</p>
        </div>
        <span @click="play14">查看</span>
      </div>
    </div>
    <BarStacked ref="barStacked" id="barStacked1" customClass="stack_bar" />
  </div>
</template>
<script>
import BarStacked from "@/components/barStacked/index.vue";
export default {
  data: function () {
    return {
      data: [
        {
          label: "2018151515",
          value1: 2000,
          value2: 2171,
          value3: 1256,
          value4: 2121,
          des: "净利润2072w",
        },
        {
          label: "2019",
          value1: 3000,
          value2: 3268,
          value3: 1256,
          value4: 2121,
          des: "净利润2022w",
        },
        {
          label: "2020",
          value1: 4000,
          value2: 4542,
          value3: 1256,
          value4: 2121,
          des: "净利润2576w",
        },
        {
          label: "2021",
          value1: 1997,
          value2: 4537,
          value3: 1556,
          value4: 2121,
          des: "净利润2576w",
        },
        {
          label: "2022",
          value1: 1000,
          value2: 1542,
          value3: 4256,
          value4: 4121,
          des: "净利润2576w",
        },
      ],
      data2: [
        {
          label: "2018",
          value1: 2000,
          value2: 2171,
          des: "净利润2072w",
        },
        {
          label: "2019",
          value1: 3000,
          value2: 3268,
          des: "净利润2022w",
        },
        {
          label: "2020",
          value1: 4000,
          value2: 4542,
          des: "净利润2576w",
        },
      ],
    };
  },
  components: {
    BarStacked,
  },
  mounted() {},
  methods: {
    play1() {
      this.$refs.barStacked.StackedBarChart(this.data, {
        y: (d) => d.label,
        width: 490, // the outer width of the chart, in pixels
        height: 300, //
        marginTop: 0, // the top margin, in pixels
        marginRight: 90, // the right margin, in pixels
        marginBottom: 0, // the bottom margin, in pixels
        marginLeft: 'auto', // the left margin, in pixels
        yPadding:0.5,
        numberSuffix: "w",
        isPopOverVisible: false,
      });
    },
    playOut() {
      this.$refs.barStacked.playOut();
    },
    play2() {
      this.$refs.barStacked.StackedBarChart(this.data2, {
        y: (d) => d.label,
        numberSuffix: "w",
        isPopOverVisible: true,
        yPadding:0.7,
        marginLeft: 100,
      });
    },
    play3() {
      this.$refs.barStacked.StackedBarChart(this.data, {
        y: (d) => d.label,
        numberSuffix: "w",
        isPopOverVisible: true,
        padding: 0.6,
      });
    },
    play4() {
      this.$refs.barStacked.StackedBarChart(this.data, {
        y: (d) => d.label,
        numberSuffix: "w",
        isPopOverVisible: true,
        duration: 2000,
      });
    },
    play5() {
      this.$refs.barStacked.StackedBarChart(this.data, {
        y: (d) => d.label,
        numberSuffix: "w",
        isPopOverVisible: true,
        delay: 2000,
      });
    },
    play6() {
      this.$refs.barStacked.StackedBarChart(this.data, {
        y: (d) => d.label,
        numberSuffix: "w",
        isPopOverVisible: true,
        ease: "easeQuad",
      });
    },
    play7() {
      this.$refs.barStacked.StackedBarChart(this.data, {
        y: (d) => d.label,
        numberSuffix: "w",
        isPopOverVisible: true,
        numberVisible: false,
      });
    },
    play8() {
      this.$refs.barStacked.StackedBarChart(this.data, {
        y: (d) => d.label,
        isPopOverVisible: true,
      });
    },
    play9() {
      this.$refs.barStacked.StackedBarChart(this.data, {
        y: (d) => d.label,
        numberSuffix: "w",
        isPopOverVisible: true,
        numberColor: "red",
      });
    },
    play10() {
      this.$refs.barStacked.StackedBarChart(this.data, {
        y: (d) => d.label,
        numberSuffix: "w",
        isPopOverVisible: true,
        popoverWidth: 100,
      });
    },
    play11() {
      this.$refs.barStacked.StackedBarChart(this.data, {
        y: (d) => d.label,
        numberSuffix: "w",
        isPopOverVisible: true,
        popoverHeight: 30,
      });
    },
    play12() {
      this.$refs.barStacked.StackedBarChart(this.data, {
        y: (d) => d.label,
        numberSuffix: "w",
        isPopOverVisible: true,
        isPopOverVisible: false,
      });
    },
    play13() {
      this.$refs.barStacked.StackedBarChart(this.data, {
        y: (d) => d.label,
        numberSuffix: "w",
        isPopOverVisible: true,
        colors: ["yellow", "green"],
      });
    },
    play14() {
      this.$refs.barStacked.StackedBarChart(this.data, {
        y: (d) => d.label,
        numberSuffix: "w",
        isPopOverVisible: true,
        marginRight: 120,
        colors: ["red", "green", "yellow", "blue"],
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bar_stacked {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  .holder {
    > div {
      display: flex;
      align-items: center;
      h1 {
        font-size: 18px;
        color: #9c27b0;
        margin-bottom: 10px;
        span {
          color: #fff;
          background: #f00;
          padding: 8px;
          border-radius: 5px;
          margin-right: 10px;
        }
      }
      p {
        margin-bottom: 24px;
        color: #555;
      }
      > span {
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        height: 24px;
        padding: 4px 8px;
        background: #447746;
        color: #fff;
        margin-left: 56px;
      }
    }
  }
}

.stack_bar {
  background: rgba(255, 255, 255, 0.5);
  color: #f00;
  position: fixed;
  right: 10px;
  bottom: 10px;
  font-size: 16px;
  /deep/ .text {
    font-size: 16px;
  }
}
</style>
